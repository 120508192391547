import { API } from 'api';
import { action, Action, computed, Computed, thunk } from 'easy-peasy';
import { API_HTTP_CODES } from '../constants';
import {
  formatMoney,
  sortFunds,
  factories,
  runApi,
  createBalancesByAsset,
} from '../utils';
import { DataModel } from './data-store';
import { TransferFormikProps } from '../utils/formik/transfer/types';
import { ApiError } from './api-error';
import { ApiThunk } from './types';
import {
  EnrichedAccountDetail,
  EnrichedInternalTransfer,
  EnrichedAccountDetailAsset,
  Enriched,
  Balances,
} from '../types';
import { SORT_ASSETS_BY } from '../config';
import { createEnrichedInternalTransfer } from '../utils/factories/enriched-internal-transfer-factory';
import { getAccountsByWorkflow } from '../hooks';
import { BaseModel, createBaseModel } from './base-store';

const initialFormValues = {
  currencyCode: null,
  amount: null,
  sourceAccountId: null,
  destinationAccountId: null,
  description: null,
};

export interface TransferModel extends BaseModel {
  // state
  formValues: Partial<TransferFormikProps> | null;
  transfer: EnrichedInternalTransfer | null;
  _sourceAccountDetail: API.AccountDetail | null;
  destinationAccountDetail: API.AccountDetail | null;
  //computed
  assetBalances: Computed<TransferModel, Balances | null, DataModel>;
  sourceAccountId: Computed<TransferModel, string, DataModel>;
  sourceAccountDetail: Computed<
    TransferModel,
    EnrichedAccountDetail | null,
    DataModel
  >;
  sourceAccountAllowedAssets: Computed<
    TransferModel,
    EnrichedAccountDetailAsset[]
  >;
  destinationAccountAssetBalance: Computed<
    TransferModel,
    string | null,
    DataModel
  >;
  allowedCanTransferAccounts: Computed<
    TransferModel,
    Enriched.ListAccountItem[],
    DataModel
  >;
  destinationAccounts: Computed<
    TransferModel,
    Enriched.ListAccountItem[],
    DataModel
  >;
  // actions
  setFormValues: Action<TransferModel, Partial<TransferFormikProps> | null>;
  setSourceAccountDetail: Action<TransferModel, API.AccountDetail | null>;
  setSourceAccountId: Action<TransferModel, string>;
  setTransfer: Action<TransferModel, EnrichedInternalTransfer | null>;
  reset: Action<TransferModel, void>;
  setDestinationAccountDetail: Action<TransferModel, API.AccountDetail | null>;
  // thunk
  simulate: ApiThunk<TransferModel, void, API.InternalTransfer | null>;
  getTransfer: ApiThunk<
    TransferModel,
    string,
    API.InternalTransferWithTimeline | null
  >;
  getSourceAccountDetail: ApiThunk<
    TransferModel,
    { accountId: string },
    API.AccountDetail | null
  >;
  getDestinationAccountDetail: ApiThunk<
    TransferModel,
    { accountId: string },
    API.AccountDetail | null
  >;
  create: ApiThunk<
    TransferModel,
    string | null | undefined,
    API.InternalTransfer | null
  >;
  isConfirmedCode: boolean;
  setIsConfirmedCode: Action<TransferModel, boolean>;
  confirmCode: ApiThunk<TransferModel, string, unknown>;
  resendCode: ApiThunk<TransferModel, void, unknown>;
  cancel: ApiThunk<TransferModel, void, unknown>;
}

export const transferModel: TransferModel = {
  ...createBaseModel(),

  // state
  formValues: null,
  transfer: null,
  _sourceAccountDetail: null,
  destinationAccountDetail: null,
  // computed
  assetBalances: computed(
    [
      s => s.formValues?.currencyCode ?? null,
      s => s.sourceAccountDetail?.assets ?? [],
    ],
    (currencyCode, assets) => {
      if (!currencyCode) {
        return null;
      }

      const asset = assets.find(
        _asset => _asset.currency.code === currencyCode
      );

      if (!asset) {
        return null;
      }

      return createBalancesByAsset(asset);
    }
  ),
  sourceAccountId: computed(
    [
      (_state, storeState) =>
        storeState.portfolio.accountDetail?.account?.accountId ?? '',
      state => state.formValues?.sourceAccountId,
    ],
    (portfolioAccountId, selectedAccountId) => {
      if (selectedAccountId) {
        return selectedAccountId;
      }
      return portfolioAccountId;
    }
  ),
  sourceAccountDetail: computed(
    [
      (_s, storeState) => storeState.client,
      s => s._sourceAccountDetail,
      (_s, storeState) => storeState.metaData.currencies,
      (_s, storeState) => storeState.portfolio.accountDetail,
      (_s, storeState) => storeState.metaData.fiatCurrencyCodes,
      (_s, storeState) => storeState.portfolio.accounts,
      (_s, storeState) => storeState.settings.globalAppSettings,
    ],
    (
      client,
      _accountDetail,
      currencies,
      portfolioAccountDetail,
      fiatCurrencyCodes,
      accounts,
      globalAppSettings
    ) => {
      if (!_accountDetail) {
        return portfolioAccountDetail;
      }
      return factories.enrichAccountDetail(
        client,
        _accountDetail,
        currencies,
        fiatCurrencyCodes,
        accounts,
        globalAppSettings
      );
    }
  ),
  sourceAccountAllowedAssets: computed(
    [s => s.sourceAccountDetail],
    sourceAccountDetail => {
      return sortFunds(
        (sourceAccountDetail?.assetsWithBalanceOrEarnedInterest ?? []).filter(
          _asset => {
            return _asset.canAccountTransfer && _asset.currency.isAssetOfTypeFiat
              ? sourceAccountDetail?.canSendCash && _asset.hasBalance
              : sourceAccountDetail?.canSendCrypto && _asset.hasBalance;
          }
        ),
        SORT_ASSETS_BY.BALANCE_USD
      );
    }
  ),
  destinationAccountAssetBalance: computed(
    [
      s => s.destinationAccountDetail,
      s => s.formValues?.currencyCode,
      (_s, storeState) => storeState.metaData.currencies,
    ],
    (destinationAccountDetail, currencyCode, currencies) => {
      if (!destinationAccountDetail || !currencyCode) {
        return null;
      }

      const asset = destinationAccountDetail.assets?.find(
        a => a.currency?.code === currencyCode
      );

      const currency = currencies?.find(c => c.code === currencyCode);
      if (!asset) {
        return formatMoney(
          '0',
          currency?.displayCode ?? currencyCode,
          currency?.decimals
        );
      }
      return formatMoney(
        asset.quantity ?? '0',
        asset.currency?.displayCode ?? currencyCode,
        currency?.decimals
      );
    }
  ),
  // actions
  reset: action(state => {
    state.formValues = null;
    state._sourceAccountDetail = null;
    state.destinationAccountDetail = null;
    state.error = null;
    state.busy = false;
    state.transfer = null;
  }),
  allowedCanTransferAccounts: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-transfer',
      })
  ),
  destinationAccounts: computed(
    [
      state => state.formValues?.sourceAccountId,
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (sourceAccountId, portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-transfer-destination',
      }).filter(({ account }) => account?.accountId !== sourceAccountId)
  ),
  setFormValues: action((state, values) => {
    state.error = null;

    if (values === null) {
      state.formValues = null;
    } else {
      state.formValues = {
        ...(state.formValues ?? {}),
        ...values,
      };
    }
  }),
  setTransfer: action((state, value) => {
    state.transfer = value;
  }),
  setSourceAccountDetail: action((state, value) => {
    state._sourceAccountDetail = value;
  }),
  setSourceAccountId: action((state, value) => {
    state.formValues = {
      ...(state.formValues ?? initialFormValues),
      sourceAccountId: value,
    };
  }),
  setDestinationAccountDetail: action((state, value) => {
    state.destinationAccountDetail = value;
  }),
  // thunk
  getTransfer: thunk(async (actions, id, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getInternalTransfer({ id });
      },
      result => {
        const storeState = helpers.getStoreState();

        actions.setTransfer(
          createEnrichedInternalTransfer(result, storeState.metaData.currencies)
        );
      }
    );
  }),
  getSourceAccountDetail: thunk(async (actions, { accountId }, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        actions.setSourceAccountId(accountId);

        helpers.injections.apiClient.setAdditionalHeaders({
          'x-account-id': accountId,
        });
        return helpers.injections.apiClient.getAccountDetails();
      },
      result => {
        actions.setSourceAccountDetail(result);
      }
    );
  }),
  getDestinationAccountDetail: thunk(
    async (actions, { accountId }, helpers) => {
      return runApi(
        actions,
        helpers,
        () => {
          const { destinationAccountDetail } = helpers.getState();

          if (destinationAccountDetail?.account?.accountId !== accountId) {
            actions.setDestinationAccountDetail(null);
          }

          helpers.injections.apiClient.setAdditionalHeaders({
            'x-account-id': accountId,
          });

          return helpers.injections.apiClient.getAccountDetails();
        },
        result => {
          actions.setDestinationAccountDetail(result);
        },
        undefined,
        true
      );
    }
  ),
  simulate: thunk((actions, _void, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        const { formValues, sourceAccountId } = helpers.getState();

        if (
          !formValues?.amount ||
          !formValues?.currencyCode ||
          !formValues?.destinationAccountId
        ) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            'Unprocessable Entity',
            -1
          );
        }

        actions.setTransfer(null);

        helpers.injections.apiClient.setAdditionalHeaders({
          'x-account-id': sourceAccountId,
        });

        return helpers.injections.apiClient.simulateInternalTransfer({
          amount: formValues.amount,
          currencyCode: formValues.currencyCode,
          destinationAccountId: formValues.destinationAccountId,
          description: formValues.description || null,
          googleAuthenticatorCode: null,
          clientId: null,
          subtractFeeFromAmount: true,
          substractFeeFromAmount: true,
          appAuthenticatorCode: null,
          sourceAccountId: null,
        });
      },
      result => {
        const storeState = helpers.getStoreState();

        actions.setTransfer(
          createEnrichedInternalTransfer(result, storeState.metaData.currencies)
        );
      }
    );
  }),
  create: thunk((actions, code = null, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        const { formValues, sourceAccountId } = helpers.getState();

        if (
          !formValues?.amount ||
          !formValues?.currencyCode ||
          !formValues?.destinationAccountId
        ) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            'Unprocessable Entity',
            -1
          );
        }

        helpers.injections.apiClient.setAdditionalHeaders({
          'x-account-id': sourceAccountId,
        });

        return helpers.injections.apiClient.createInternalTransfer({
          amount: formValues.amount,
          currencyCode: formValues.currencyCode,
          destinationAccountId: formValues.destinationAccountId,
          description: formValues.description || null,
          appAuthenticatorCode: code,
          clientId: null,
          subtractFeeFromAmount: true,
          substractFeeFromAmount: true,
          googleAuthenticatorCode: null,
          sourceAccountId: null,
        });
      },
      result => {
        const storeState = helpers.getStoreState();

        actions.setTransfer(
          createEnrichedInternalTransfer(result, storeState.metaData.currencies)
        );
      },
      {
        'x-account-id': helpers.getState().formValues?.sourceAccountId,
      }
    );
  }),
  isConfirmedCode: false,
  setIsConfirmedCode: action((state, payload) => {
    state.isConfirmedCode = payload;
  }),
  confirmCode: thunk(async (actions, code, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        actions.setError(null);
        const { transfer } = helpers.getState();
        if (!transfer) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            'Unprocessable Entity',
            -1
          );
        }
        return helpers.injections.apiClient.confirmTransferCode({
          id: transfer.id,
          code,
        });
      },
      result => result,
      {
        'x-account-id': helpers.getState().formValues?.sourceAccountId,
      }
    );
  }),
  resendCode: thunk(async (actions, _void, helpers) => {
    return runApi(actions, helpers, () => {
      const { transfer } = helpers.getState();

      if (!transfer) {
        throw new ApiError(
          API_HTTP_CODES.UNPROCESSABLE_ENTITY,
          'Unprocessable Entity',
          -1
        );
      }

      return helpers.injections.apiClient.resendTransferVerificationCode({
        transferId: transfer.id,
      });
    });
  }),
  cancel: thunk((actions, _void, helpers) => {
    return runApi(actions, helpers, () => {
      const { transfer } = helpers.getState();

      if (!transfer) {
        throw new ApiError(
          API_HTTP_CODES.UNPROCESSABLE_ENTITY,
          'Unprocessable Entity',
          -1
        );
      }

      return helpers.injections.apiClient.cancelTransfer({
        transferId: transfer.id,
      });
    });
  }),
};
