import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { shallowEqualObjects } from 'shallow-equal';
import { API } from 'api';
import { StepWizardChildProps } from 'react-step-wizard';
import { Formik, Form as FormikForm } from 'formik';
import {
  bankAccountItemTemplate,
  bankAccountSelectedItemTemplate,
  fiatFundWithBalanceItemTemplate,
  fiatFundWithBalanceSelectedItemTemplate,
} from '../../app-selector/templates';
import {
  DEFAULTS,
  formatAmount,
  WithdrawCashFormikProps,
  WithdrawCashFormikField,
  validateWithdrawCashFormik,
  useComputedConfig,
  LabelForFormElement,
  Note,
  CurrencyIcon,
  TIMERS,
  NUMBER_FORMAT,
  decimalNumberValidation,
  FIAT_SYMBOLS,
  EnrichedAccountDetailAsset,
  FormHeader,
} from 'common';
import { WithdrawCashSteps } from './steps';
import { AppStore, DataStore } from '../../../store';
import { SelectField } from '../../forms/select-field';
import { NumberField } from '../../forms/number-field';
import { AppIcon } from 'common';
import debounce from 'lodash.debounce';
import { FormFooter } from '../shared/form-footer';
import { IconContent } from '~/components/icon-content';
import { TextAreaField } from '~/components/forms/textarea-field';
import { NotAllowed } from '../shared/not-allowed';
import { getAccountInfo, getAccountType } from '~/utils/get-account-details';
import { AppTooltip } from '~/components/app-tooltip';
import { AssetBalanceBreakdown } from '../shared/asset-balance-breakdown';

export const Form: FC<Partial<StepWizardChildProps>> = memo(
  ({ goToNamedStep }) => {
    if (!goToNamedStep) {
      return null;
    }

    /**
     * Store
     */
    const busy = DataStore.useStoreState(s => s.withdrawCash.busy);
    const error = DataStore.useStoreState(s => s.withdrawCash.error);
    const setError = DataStore.useStoreActions(a => a.withdrawCash.setError);
    const isClient = DataStore.useStoreState(s => s.user.isClient);
    const bankAccounts = DataStore.useStoreState(
      s => s.withdrawCash.bankAccounts
    );
    const assetBalances = DataStore.useStoreState(
      s => s.withdrawCash.assetBalances
    );
    const hasNonVerifiedBankAccounts = DataStore.useStoreState(
      s => s.settings.hasNonVerifiedBankAccounts
    );
    const getBankAccounts = DataStore.useStoreActions(
      a => a.settings.getBankAccounts
    );
    const accountDetail = DataStore.useStoreState(
      s => s.portfolio.accountDetail
    );
    const formValues = DataStore.useStoreState(s => s.withdrawCash.formValues);
    const allowedAssets = DataStore.useStoreState(
      a => a.withdrawCash.allowedAssets
    );
    const simulation = DataStore.useStoreState(s => s.withdrawCash.simulation);
    const setSimulation = DataStore.useStoreActions(
      a => a.withdrawCash.setSimulation
    );
    const setFormValues = DataStore.useStoreActions(
      a => a.withdrawCash.setFormValues
    );
    const simulate = DataStore.useStoreActions(a => a.withdrawCash.simulate);
    const setAsset = DataStore.useStoreActions(a => a.withdrawCash.setAsset);
    const dashboardSelectedAsset = AppStore.useStoreState(
      s => s.dashboardSelectedAsset
    );
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );

    /**
     * Hooks
     */
    const { tenant } = useComputedConfig();
    useEffect(() => {
      getBankAccounts();
    }, []);

    /**
     * Methods
     */
    const debouncedSimulate = useMemo(
      () => debounce(simulate, TIMERS.SIMULATE_DEBOUNCE),
      []
    );
    const onFormValidate = (values: WithdrawCashFormikProps) => {
      return validateWithdrawCashFormik(values).then(async (errors = {}) => {
        if (shallowEqualObjects(formValues, values)) {
          return errors;
        }
        const fieldsHaveValues = Boolean(
          !!values.fromAsset && !!values.amount && !!values.bankAccount
        );

        const hasErrors = Object.keys(errors).length > 0;
        if (
          !hasErrors &&
          fieldsHaveValues &&
          values.fromAsset?.currency.code === values.bankAccount?.currency?.code
        ) {
          setError(null);
          await debouncedSimulate(values);
        }
        return errors;
      });
    };
    const onFormSubmit = async () => {
      setError(null);
      goToNamedStep(WithdrawCashSteps.Preview);
    };

    /**
     * DOM
     */
    if (!accountDetail) {
      return null;
    }
    if (!allowedAssets || allowedAssets.length <= 0) {
      return (
        <NotAllowed
          message={`There are no assets in your account ${getAccountType(
            accountDetail?.account
          )} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    const fromAsset = formValues?.fromAsset || dashboardSelectedAsset || null;
    if (
      fromAsset &&
      !fromAsset.currency.isAssetOfTypeFiat &&
      !fromAsset.currency.isAssetOfTypeFund
    ) {
      return (
        <NotAllowed
          message={`Withdraw cash operation is not allowed for ${fromAsset.currency.displayCode}`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    if (
      fromAsset &&
      !allowedAssets.find(a => a.currency.code === fromAsset.currency.code)
    ) {
      // chosen asset is not in account!
      return (
        <NotAllowed
          message={`There is no ${
            fromAsset.currency.displayCode
          } in your account ${getAccountType(accountDetail?.account)} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }

    const matchingBankAccount = bankAccounts.find(
      bankAccount => bankAccount.currency?.code == fromAsset?.currency?.code
    );
    const initialValues: WithdrawCashFormikProps = {
      fromAsset: fromAsset,
      bankAccount: formValues?.bankAccount || matchingBankAccount || null,
      amount: formValues?.amount || null,
      description: formValues?.description || '',
    };
    return (
      <div className="flex flex-col">
        {/* form  */}
        <Formik<WithdrawCashFormikProps>
          initialValues={initialValues}
          validate={onFormValidate}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange
          onSubmit={onFormSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            submitForm,
            isValidating,
            setValues,
          }) => {
            const fundBalance = Number(values.fromAsset?.withdrawableQuantity);
            const hasErrors = Object.values(errors).length > 0;

            /**
             * Handle Methods
             */
            const handleOnAddBank = (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              e.preventDefault();
              setFormValues(values);
              goToNamedStep(WithdrawCashSteps.AddBank);
            };
            const handleOnFundChanged = async (
              newValue: EnrichedAccountDetailAsset
            ) => {
              if (isValidating) {
                return;
              }
              setSimulation(null);
              setError(null);
              setAsset(newValue);
              await setValues(
                {
                  ...values,
                  fromAsset: newValue,
                  bankAccount: null,
                  amount: '',
                },
                false
              );
            };
            const handleOnBankAccountChanged = async (
              newValue: API.BankAccount
            ) => {
              if (isValidating) {
                return;
              }
              await setFieldValue(
                WithdrawCashFormikField.bankAccount,
                newValue,
                false
              );
            };
            const handleOnMaxButtonClicked = async () => {
              if (isValidating) {
                return;
              }
              const formattedBalance = formatAmount(
                fundBalance,
                ccyInfo?.decimals
              );
              await setFieldValue(
                WithdrawCashFormikField.amount,
                formattedBalance,
                true
              );
            };
            const handleOnCancelButtonClicked = useCallback(
              (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                setSelectedDialogType(null);
              },
              [setSelectedDialogType]
            );
            const handleOnNextButtonClicked = (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              e.preventDefault();
              submitForm();
            };

            const handleOnAmountChanged = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(
                e.target.name,
                decimalNumberValidation(e.target.value),
                true
              );
              setFieldTouched(e.target.name, true, false);
            };

            const debouncedOnAmountChanged = debounce(
              handleOnAmountChanged,
              TIMERS.FORMIK_DEBOUNCE
            );

            const handleOnDescriptionChanged = (
              e: React.ChangeEvent<HTMLTextAreaElement>
            ) => {
              setFieldTouched(e.target.name, true, false);
              setFieldValue(e.target.name, e.target.value, true);
            };

            const debounceOnDescriptionChanged = debounce(
              handleOnDescriptionChanged,
              TIMERS.FORMIK_DEBOUNCE
            );

            /**
             * Form DOM
             */
            const ccyInfo = values.fromAsset?.currency;
            const hasNoBankAccounts = !bankAccounts.length;
            const isDisabled =
              busy ||
              isValidating ||
              !simulation ||
              error !== null ||
              hasErrors ||
              !formValues;

            return (
              <FormikForm>
                {/* header  */}
                <FormHeader
                  cls="mb-4"
                  title={`Withdraw ${
                    values.fromAsset?.currency.displayCode || ''
                  }`}
                  accountInfo={getAccountInfo(accountDetail.account, 'From')}
                />
                <div className="px-6 md:px-10 pb-2">
                  {/* from fund  */}
                  <SelectField
                    name={WithdrawCashFormikField.fromAsset}
                    label="Choose currency"
                    values={allowedAssets}
                    disableHelpers
                    getSelectedItemTemplate={
                      fiatFundWithBalanceSelectedItemTemplate
                    }
                    getItemTemplate={(
                      fund,
                      selected: boolean,
                      active: boolean
                    ) =>
                      fiatFundWithBalanceItemTemplate(fund, selected, active)
                    }
                    onChange={handleOnFundChanged}
                  />

                  <AssetBalanceBreakdown
                    availableToTitle="Available to withdraw now"
                    balances={assetBalances}
                    breakdownType="pendingOutgoing"
                  />

                  {/* method = bank */}
                  <div className="flex flex-col mt-4">
                    <div className="flex flex-row justify-between items-center">
                      <LabelForFormElement title="Choose bank" />
                      {!hasNoBankAccounts && !isClient && (
                        <button
                          type={'button'}
                          className="typo-b3 font-bold flex flex-row items-center mt-4 mb-1"
                          tabIndex={-1}
                          onClick={handleOnAddBank}
                        >
                          <AppIcon icon={'plus'} size={24} bg="" cls={'mr-'} />
                          Add another account
                        </button>
                      )}
                    </div>
                    {hasNoBankAccounts && (
                      <div className="flex items-center p-3 flex-col border rounded-4">
                        {isClient ? (
                          <p>
                            Contact your {tenant} representative to add a bank
                            account
                          </p>
                        ) : (
                          <button
                            tabIndex={-1}
                            type={'button'}
                            data-testid="add-bank-account-no-bank"
                            className="typo-b1 font-bold flex flex-row items-center typo-b2"
                            onClick={handleOnAddBank}
                          >
                            <AppIcon icon="plus" bg="" size={24} cls={'mr-1'} />{' '}
                            Add a bank account
                          </button>
                        )}
                      </div>
                    )}
                    {!hasNoBankAccounts && (
                      <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                        <SelectField
                          name={WithdrawCashFormikField.bankAccount}
                          values={bankAccounts}
                          disabled={hasNoBankAccounts}
                          getSelectedItemTemplate={
                            bankAccountSelectedItemTemplate
                          }
                          getItemTemplate={bankAccountItemTemplate}
                          onChange={handleOnBankAccountChanged}
                        />
                      </div>
                    )}
                    {hasNonVerifiedBankAccounts && (
                      <div className="flex flex-col my-2 sm:mb-0 sm:flex-1 ">
                        <Note>
                          <IconContent
                            content={
                              <>
                                You have pending bank account(s) that has not
                                been verified yet. You may verify it under your
                                settings - payment methods.
                              </>
                            }
                          />
                        </Note>
                      </div>
                    )}
                  </div>

                  {/* amount  */}
                  <div className="flex flex-col flex-1 mt-4">
                    <NumberField
                      name={WithdrawCashFormikField.amount}
                      label="Amount"
                      placeholder="Enter amount"
                      allowNegative={false}
                      decimalScale={ccyInfo?.decimals || DEFAULTS.DECIMAL_SCALE}
                      disabled={fundBalance <= 0 || hasNoBankAccounts}
                      autoComplete="off"
                      thousandSeparator={NUMBER_FORMAT.THOUSANDS_SEPARATOR}
                      leftAddon={
                        !!values.fromAsset?.currency?.code &&
                        !hasNoBankAccounts ? (
                          <div className="py-2 h-full w-1/4">
                            <CurrencyIcon
                              currencyCode={values.fromAsset?.currency?.code}
                              apy={values.fromAsset?.currency?.apy}
                            />
                          </div>
                        ) : undefined
                      }
                      rightAddon={
                        fundBalance &&
                        !(fundBalance <= 0 || hasNoBankAccounts) ? (
                          <button
                            type="button"
                            className="text-sm font-bold underline focus:outline-none relative mb-1"
                            onClick={handleOnMaxButtonClicked}
                          >
                            Max
                          </button>
                        ) : undefined
                      }
                      onChange={debouncedOnAmountChanged}
                    />
                  </div>

                  {/* from fund & balance info */}
                  {simulation && !hasErrors && !!Number(simulation.fee) && (
                    <Note cls="py-3.5 mt-2 text-sm items-center flex flex-row justify-between gap-2">
                      <div className="flex flex-row items-center gap-2">
                        Fee:{' '}
                        <p className="font-bold">
                          {simulation.currencyCode &&
                            FIAT_SYMBOLS[simulation.currencyCode]}
                          {simulation.fee}
                        </p>
                      </div>
                      <AppTooltip
                        effect="solid"
                        place="top"
                        event="click"
                        content={
                          <div className="my-1">{`The fee listed will be charged to your Stablehouse account at the end of the month.`}</div>
                        }
                      />
                    </Note>
                  )}

                  {/* description */}
                  <div className="flex flex-col mt-4">
                    <TextAreaField
                      label="Description"
                      placeholder={'Add description'}
                      maxLength={1024}
                      name={WithdrawCashFormikField.description}
                      onChange={debounceOnDescriptionChanged}
                    />
                  </div>
                </div>

                {/* actions  */}
                <FormFooter
                  error={error}
                  onSubmit={handleOnNextButtonClicked}
                  onCancel={handleOnCancelButtonClicked}
                  isSubmitDisabled={isDisabled}
                  submitText="Preview"
                />
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    );
  }
);
