import cx from 'classnames';
import { API } from 'api';
import {
  AppIcon,
  Country,
  CurrencyIcon,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedCryptoAddress,
  EnrichedCurrency,
  formatMoney,
  PriceCell,
  textItemTemplate,
  textSelectedItemTemplate,
} from 'common';
import { BankAccountInfo } from '../bank-accounts/bank-account-info';
import { getAccountInfo } from '~/utils/get-account-details';
import { FlagIcon } from '../flag-icon';

export const singleFundSelectedItemTemplate = (
  fund?: EnrichedAccountDetailAsset,
  showPendingBalance = true
) => {
  if (!fund) {
    return (
      <p data-testid="select-a-value" className="text-grey text-sm font-bold">
        Please select
      </p>
    );
  }
  return (
    <CurrencyIcon
      currencyCode={fund.currency.code}
      currencyName={`${
        showPendingBalance
          ? fund.formatted.balanceIncludingPending
          : fund.formatted.balance
      } available`}
      apy={fund.currency.apy}
    />
  );
};

export const singleFundItemTemplate = (
  fund: EnrichedAccountDetailAsset | EnrichedAssetHolding,
  selected: boolean,
  active: boolean,
  canEarn?: boolean,
  showPendingBalance = true
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-4 py-1',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <div className="w-40">
        <CurrencyIcon
          currencyCode={fund.currency.code}
          apy={fund.currency.apy}
        />
      </div>
      <span className="text-xs text-gray-400 ml-1 text-right">
        <span>
          {showPendingBalance
            ? fund.formatted.balanceIncludingPending
            : fund.formatted.balance}
        </span>
        {canEarn && (
          <span className="inline-block" style={{ width: 60 }}>
            {fund.currency.apy || 0}% APY
          </span>
        )}
      </span>
    </div>
  );
};

export const singleCurrencySelectedItemTemplate = (curr?: EnrichedCurrency) => {
  if (!curr) {
    return (
      <p data-testid="select-a-value" className="text-grey text-sm font-bold">
        Please select
      </p>
    );
  }
  return <CurrencyIcon currencyCode={curr.code} currencyName={curr.name} size={32} apy={curr.apy} />;
};
export const singleCurrencyItemTemplate = (
  curr: EnrichedCurrency,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-4 py-3 border-b',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <CurrencyIcon currencyCode={curr.code} currencyName={curr.name} size={32} apy={curr.apy} />
    </div>
  );
};

export const tradeAssetsTemplate = (
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        {
          'bg-gray-100': selected,
          'bg-grey-brightest': active,
        },
        'flex flex-row justify-between px-5 py-2.5 border-b items-center '
      )}
    >
      <span className="flex flex-row gap-x-2.5 items-center">
        <CurrencyIcon
          showLabel
          size={20}
          currencyCode={asset?.currency.code}
          currencyName={
            <span className="text-grey-darker">{asset?.currency.name}</span>
          }
          apy={asset.currency.apy}
        />
      </span>
      <span className="flex flex-row items-center gap-x-2 text-right">
        <span className="flex flex-col text-right">
          <span className="text-primary font-bold text-sm">
            {formatMoney(asset?.currency.fxRate.rate.toString(), 'USD')}
          </span>
          <span className="flex flex-row items-center">
            <span className="text-grey-darker text-xs mr-1">
              {formatMoney(
                asset?.currency.fxRate.rate24HoursAgoValueChange?.toString() ??
                  '0',
                'USD'
              )}
            </span>
            <span
              className={cx('flex flex-row items-center font-bold text-xs', {
                'text-success':
                  Number(
                    asset?.currency.fxRate.rate24HoursAgoPercentageChange
                  ) > 0,
                'text-failure':
                  Number(
                    asset?.currency.fxRate.rate24HoursAgoPercentageChange
                  ) < 0,
                'text-primary':
                  Number(
                    asset?.currency.fxRate.rate24HoursAgoPercentageChange
                  ) === 0,
              })}
            >
              &#40;
              <PriceCell
                textSizeCls="text-xs"
                value={Number(
                  asset?.currency.fxRate.rate24HoursAgoPercentageChange
                )}
              />
              &#41;
            </span>
          </span>
        </span>
      </span>
    </div>
  );
};

export const fiatFundSelectedItemTemplate = (
  currencyCode?: string,
  value?: string
) => {
  if (!currencyCode) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Please select
      </p>
    );
  }
  return (
    <CurrencyIcon
      currencyCode={currencyCode}
      currencyName={value ? `${value} available` : ''}
    />
  );
};

export const fiatFundItemTemplate = (
  currencyCode: string,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-4 py-1',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <div className="w-40">
        <CurrencyIcon currencyCode={currencyCode} />
      </div>
    </div>
  );
};

export const fiatFundWithBalanceSelectedItemTemplate = (
  fund?: EnrichedAccountDetailAsset,
  showBalance = true
) => {
  if (!fund) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Please select
      </p>
    );
  }
  if (!showBalance) {
    return (
      <CurrencyIcon
        showLabel
        currencyCode={fund.currency.code}
        currencyName={fund.currency?.name}
      />
    );
  }
  return (
    <div className="flex justify-between items-center py-1 gap-2">
      <div>
        <CurrencyIcon showLabel={false} currencyCode={fund.currency.code} />
      </div>

      <div className="flex flex-col items-start">
        <span className="text-sm text-primary font-bold">
          {fund.currency.displayCode || fund.currency.code.toLocaleUpperCase()}
        </span>
        <span className="text-xs font-normal text-primary">
          {fund.formatted.balanceWithCurrencyCode} available
        </span>
      </div>
    </div>
  );
};

export const fiatFundWithBalanceItemTemplate = (
  fund: EnrichedAccountDetailAsset,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-4 py-1',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <div className="w-40">
        <CurrencyIcon currencyCode={fund.currency.code} />
      </div>

      <span className="text-xs text-gray-400 ml-1">
        {fund.formatted.balanceWithCurrencyCode}
      </span>
    </div>
  );
};

export const currencySelectedItemTemplate = (
  ccy: API.CurrencyInformation,
  showLabel = false
) => {
  if (!ccy) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Please select
      </p>
    );
  }
  return (
    <div className="flex justify-between items-center">
      <CurrencyIcon
        showLabel={showLabel}
        currencyCode={ccy.code}
        apy={ccy.apy}
      />
    </div>
  );
};

export const currencyItemTemplate = (
  ccy: API.CurrencyInformation,
  selected: boolean,
  active: boolean,
  showLabel = false
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-4 py-1',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <div className="w-40">
        <CurrencyIcon
          showLabel={showLabel}
          currencyCode={ccy.code}
          apy={ccy.apy}
        />
      </div>
    </div>
  );
};

export const bankAccountSelectedItemTemplate = (account: API.BankAccount) => {
  if (!account) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Please select
      </p>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <CurrencyIcon currencyCode={account.currency?.code} showLabel={false} />
      <BankAccountInfo showBankName account={account} showMasked={false} />
    </div>
  );
};

export const bankAccountItemTemplate = (
  account: API.BankAccount,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex items-center px-4 py-1 border-b gap-2',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <CurrencyIcon currencyCode={account.currency?.code} showLabel={false} />
      <BankAccountInfo
        showBankName
        account={account}
        showMasked={false}
        cls="flex flex-row justify-between items-center w-full"
      />
    </div>
  );
};

export const countrySelectedItemTemplate = (country: Country) => (
  <span className="flex flex-row items-center gap-2.5">
    <FlagIcon countryCode={country.code} />
    {textSelectedItemTemplate(country.name)}
  </span>
);

export const countryItemTemplate = (
  country: Country,
  selected: boolean,
  active: boolean
) => (
  <span className="flex flex-row px-3 py-1 gap-2.5">
    <FlagIcon countryCode={country.code} />
    {textItemTemplate(country.name, selected, active, false, '')}
  </span>
);

export const enrichedAssetSelectedItemTemplate = (
  fund: EnrichedAccountDetailAsset
) => {
  if (!fund) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        -
      </p>
    );
  }
  return (
    <CurrencyIcon currencyCode={fund.currency.code} apy={fund.currency.apy} />
  );
};

export const enrichedAssetItemTemplate = (
  asset?: EnrichedAccountDetailAsset,
  selected?: boolean,
  active?: boolean,
  placeholder?: string
) => {
  if (!asset) {
    return (
      <p data-testid="select-a-value" className="text-grey text-sm font-bold">
        {placeholder ? placeholder : '-'}
      </p>
    );
  }
  return (
    <div
      className={cx(
        'flex justify-between items-center px-1 py-1',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <div className="w-40">
        <CurrencyIcon
          currencyCode={asset.currency.code}
          currencyName={asset.currency.name}
          apy={asset.currency.apy}
        />
      </div>
    </div>
  );
};

export const addressItemTemplate = (
  address: EnrichedCryptoAddress,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex items-center px-4 py-1 gap-x-2',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <CurrencyIcon currencyCode={address.currencyCode} showLabel={false} />
      <div>
        <div className="font-bold">{address.label}</div>
        <div className="text-xs text-gray-400">{address.address}</div>
      </div>
    </div>
  );
};

export const addressSelectedItemTemplate = (
  address?: EnrichedCryptoAddress | null
) => {
  if (!address) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Please select
      </p>
    );
  }

  return (
    <div className={cx('flex py-1 gap-x-2')}>
      <CurrencyIcon currencyCode={address.currencyCode} showLabel={false} />
      <div>
        <div className="text-left font-bold">{address.label}</div>
        <div className="text-xs text-gray-400">{address.address}</div>
      </div>
    </div>
  );
};

export const accountItemTemplate = (
  account: Enriched.ListAccountItem,
  selected: boolean,
  active: boolean
) => {
  const iconType =
    account.account?.accountType === API.AccountType.Pool ||
    account.account?.accountType === API.AccountType.Interest
      ? 'negative-circle-transfer'
      : 'negative-circle-vault';

  return (
    <div
      className={cx(
        'flex items-center px-4 py-1 gap-x-2',
        { 'bg-gray-100': selected },
        { 'bg-grey-brightest': active }
      )}
    >
      <AppIcon icon={iconType} size="md" />
      <div>
        <div className="font-bold text-sm text-secondary">
          {account.account?.label}
        </div>
        <div className="font-normal text-xs text-secondary">
          {getAccountInfo(account.account)}
        </div>
      </div>
    </div>
  );
};

export const accountSelectedItemTemplate = (
  account?: Enriched.ListAccountItem
) => {
  if (!account) {
    return (
      <p
        data-testid="select-a-value"
        className="text-gray-400 text-sm font-normal"
      >
        Choose account
      </p>
    );
  }
  const iconType =
    account.account?.accountType === API.AccountType.Pool ||
    account.account?.accountType === API.AccountType.Interest
      ? 'negative-circle-transfer'
      : 'negative-circle-vault';

  return (
    <div className={cx('flex items-center py-1 gap-x-2')}>
      <AppIcon icon={iconType} size="md" />
      <div className="flex flex-col items-start">
        <div className="font-bold text-sm text-primary">
          {account.account?.label}
        </div>
        <div className="font-normal text-xs text-primary">
          {getAccountInfo(account?.account)}
        </div>
      </div>
    </div>
  );
};
