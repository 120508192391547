import { memo, useCallback, useState } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppIcon, UserBanner, UserSplashScreen } from 'common';
import CheckBox from 'rc-checkbox';
import { AppHeader } from '../app-header';
import { DataStore } from '~/store';
import { API } from 'api';

const getClassNameByType = (type?: API.MessageTemplateAlertLevel | null) => {
  switch (type) {
    case API.MessageTemplateAlertLevel.Warning:
      return 'bg-warning';
    case API.MessageTemplateAlertLevel.Error:
      return 'bg-failure';
    case API.MessageTemplateAlertLevel.Success:
      return 'bg-success';
    case API.MessageTemplateAlertLevel.Info:
    default:
      return 'bg-accent';
  }
};

export interface BannerProps {
  onDismiss: (id: string, arg?: () => void) => void;
}

export const DismissibleBanner: React.FC<
  Partial<StepWizardChildProps & UserBanner & BannerProps>
> = memo(
  ({
    nextStep,
    id,
    text,
    title,
    linkUrl,
    linkText,
    dismissable,
    onDismiss,
    alertLevel,
  }) => {
    if (!id) {
      return null;
    }

    return (
      <>
        <div
          className={cx(
            'py-3 flex flex-row justify-center items-center relative',
            getClassNameByType(alertLevel)
          )}
        >
          <div className="flex flex-col md:flex-row font-normal gap-1 text-center items-center">
            {title && <p className="text-primary font-semibold">{title}</p>}
            <span className="text-sm text-primary">{text}</span>

            <span className="flex flex-row items-center gap-2">
              {linkUrl && (
                <a
                  className="text-primary hover:underline text-sm font-bold"
                  href={linkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {linkText || 'More information'}
                </a>
              )}
            </span>
          </div>

          {dismissable && (
            <div
              className="absolute right-4 cursor-pointer"
              onClick={() => {
                if (!nextStep || !id || !onDismiss) {
                  return;
                }
                onDismiss(id, () => nextStep());
              }}
            >
              <AppIcon
                icon="close-button"
                bg=""
                fill="fill-current-color-dark"
                size="md"
              />
            </div>
          )}
        </div>
      </>
    );
  }
);

type SplashBannerProps = Partial<StepWizardChildProps & UserSplashScreen> &
  BannerProps & {
    onAccept: (id: string, arg?: () => void) => void;
  };

export const SplashBanner: React.FC<SplashBannerProps> = memo(
  ({ nextStep, onAccept, onDismiss, currentStep, totalSteps, ...rest }) => {
    if (!rest?.id) {
      return null;
    }
    /**
     * Store
     */
    const isLoading = DataStore.useStoreState(s => s.alerts.busy);

    /**
     * State
     */
    const [confirmed, setConfirmed] = useState(!rest.ackText);

    const handleNext = useCallback(() => {
      if (!nextStep || !onAccept || !rest.id) {
        return;
      }
      return onAccept(rest.id, () => {
        if (currentStep !== totalSteps) {
          return nextStep();
        }
      });
    }, [rest.id, nextStep, totalSteps, currentStep, onAccept]);

    const handleClose = useCallback(() => {
      if (!nextStep || !onAccept || !rest.id) {
        return;
      }
      return onDismiss(rest.id, () => {
        if (currentStep !== totalSteps) {
          return nextStep();
        }
      });
    }, [rest.id, nextStep, totalSteps, currentStep, onDismiss]);

    return (
      <>
        <AppHeader splashHeader />
        <div
          className={cx(
            rest.pdfUrl ? 'h-5/6 overflow-scroll' : 'h-full',
            'relative py-2 flex flex-col justify-center flex-1 items-center'
          )}
        >
          <div className="flex-1 h-full max-w-3xl w-full px-4">
            <div className="flex flex-col items-center gap-y-1 flex-1 h-5/6">
              <span className="text-primary typo-b1 font-bold sm:typo-24">
                {rest.title}
              </span>
              <span className="text-primary typo-b3 text-center text-grey-darker">
                {rest.subtitle}
              </span>
              <div className="flex-1 overflow-auto bg-white overflow-x-hidden overflow-y-auto mt-4 rounded-lg w-full">
                {rest.pdfUrl && (
                  <iframe
                    src={`https://docs.google.com/viewer?url=${rest.pdfUrl}&embedded=true`}
                    width="100%"
                    style={{ minHeight: '100%', border: 'none' }}
                  ></iframe>
                )}
                {rest.text && !rest.pdfUrl && (
                  <p className="text-primary p-6 pb-12 text-sm">{rest.text}</p>
                )}
              </div>
            </div>
          </div>

          <div
            className={cx(
              'fixed flex flex-col left-0 right-0 bg-white z-40 bottom-0 w-full bg-grey-brightest gap-2 items-center justify-center'
            )}
          >
            {!!rest.ackText && (
              <label className="flex flex-row gap-2 items-center w-full py-6 px-4 text-sm font-bold text-primary max-w-3xl">
                <CheckBox
                  data-testid="receive-attention-checkbox"
                  className="app-input-checkbox-wrap"
                  checked={confirmed}
                  onChange={() => setConfirmed(arg => !arg)}
                />
                {rest.ackText}
              </label>
            )}
            <span className="flex flex-row gap-2 items-center justify-center border-t w-full py-6 px-10 bg-grey-brighter ">
              {rest.dismissable && (
                <button
                  onClick={() => handleClose()}
                  disabled={
                    isLoading ||
                    (!rest.acceptable && !!rest.ackText ? !confirmed : false)
                  }
                  className="app-button-outline text-sm button-lg"
                >
                  {!rest.acceptable ? `Close` : `Decline`}
                </button>
              )}
              {rest.acceptable && (
                <button
                  disabled={!confirmed || isLoading}
                  onClick={() => handleNext()}
                  className="app-button-accent text-sm"
                >
                  Accept
                </button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  }
);
