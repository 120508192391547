import { AppIcon, AppValidationWrapper, TextInput, ThunkResult } from 'common';
import React, { FC, useEffect, useState } from 'react';

interface AccountEditProps {
  accountId: string;
  accountName?: string;
  accountNumber?: string;
  error?: string | null;
  onChange: (
    accountId: string,
    accountName: string
  ) => Promise<ThunkResult<void>>;
  clearError: () => void;
}

export const AccountEdit: FC<AccountEditProps> = ({
  accountId,
  accountName = '',
  accountNumber = '',
  onChange,
  error,
  clearError,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(accountName);

  /** Handlers Methods */
  const handleOnTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleConfirmChange = async () => {
    if (accountName !== value) {
      const { isSuccessful } = await onChange(accountId, value);
      if (isSuccessful) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setValue(accountName);
    clearError();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    handleConfirmChange();
  };

  if (isEditing) {
    const errorMessage = error ?? (!value ? 'Account name is required' : '');
    return (
      <div className="flex flex-row items-center justify-start">
        {/* Account Label and Number */}
        <AppValidationWrapper
          cls="max-w-sm"
          show={!!error || !value}
          message={errorMessage}
        >
          <TextInput
            type="text"
            maxLength={27}
            clsWrapper=""
            defaultValue={accountName}
            onChange={handleOnTextChange}
            onKeyPress={handleKeyDown}
            errored={!!error || !value}
          />
        </AppValidationWrapper>
        {/* Confirm Button */}
        <button
          disabled={!value}
          className="ml-2"
          onClick={handleConfirmChange}
        >
          <AppIcon icon="check" size={30} bg="" />
        </button>
        <button onClick={handleCancelClick}>
          <AppIcon icon="close-button" size={30} bg="" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-start gap-2">
      {/* Account Label and Number */}
      <div className="flex flex-col items-start">
        <span className="text-primary font-bold">{accountName}</span>
        {accountNumber && (
          <span className="text-grey-darker text-sm">{accountNumber}</span>
        )}
      </div>
      {/* Edit Button */}
      <button onClick={handleEditClick}>
        <AppIcon cls="mt-0.5" icon="pencil-line" size={20} bg="" />
      </button>
    </div>
  );
};
