import React, {
  Dispatch,
  FC,
  InputHTMLAttributes,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useRef,
} from 'react';
import cx from 'classnames';
import { AppIcon } from '../app-icon';
import { getSelectInputStyle } from './helpers';

export type InputProps = {
  errored?: boolean;
  clsWrapper?: string;
  rightAddon?: JSX.Element;
  leftAddon?: JSX.Element | null;
  rightAddonCls?: string;
  clearInput?: JSX.Element;
  handleClearInput?: () => void;
  leftAddonDisabled?: boolean;
  leftAddonCls?: string;
  leftAddonBorder?: boolean;
  value?: string | number | undefined;
  displayType?: 'input' | 'text';
  children?: (
    inputCls: string,
    leftAddonDisabled: boolean,
    node: MutableRefObject<HTMLInputElement | undefined>,
    setLeftAddonBorderSelected: Dispatch<SetStateAction<boolean>>
  ) => ReactElement | undefined;
};

export type TextInputProps = InputHTMLAttributes<HTMLInputElement>;

export function getInputStyle(
  hasError: boolean,
  withAddon = false,
  leftAddonBorder = true,
  ...classNames: string[]
) {
  return cx(
    'full',
    {
      ['errored']: hasError,
      'app-input': !withAddon,
      'app-input-with-addon': withAddon,
      'app-input-with-addon-no-divider custom-min-height':
        !leftAddonBorder && withAddon,
    },
    ...classNames
  );
}

export const InputWrapper: FC<InputProps> = ({
  value,
  leftAddon,
  clearInput = (
    <AppIcon
      icon="close-button"
      bg="bg-transparent"
      size="md"
      cls="text-gray-400"
    />
  ),
  clsWrapper = 'w-full',
  errored = false,
  rightAddon,
  handleClearInput,
  leftAddonDisabled,
  leftAddonBorder,
  children: renderInput,
  rightAddonCls,
  leftAddonCls = 'px-3',
}) => {
  const [leftAddonBorderSelected, setLeftAddonBorderSelected] =
    React.useState(false);

  /**
   * Hooks
   * */
  const inputRef = useRef<HTMLInputElement>();
  const isValidReactElement = React.isValidElement(leftAddon);
  const inputCls = getInputStyle(
    Boolean(errored),
    Boolean(leftAddon),
    leftAddonBorder
  );
  const addonCls = getSelectInputStyle(
    Boolean(errored),
    isValidReactElement,
    leftAddonBorderSelected,
    leftAddonBorder
  );

  return (
    <div className={clsWrapper}>
      <div
        className={cx(
          'flex relative',
          !rightAddon && !leftAddon && 'items-center' // Allow addons to stretch vertically naturally
        )}
      >
        {/** left addon */}
        {isValidReactElement && (
          <div
            className={cx(
              'justify-start flex flex-row items-center',
              leftAddonCls,
              addonCls
            )}
          >
            <span className={leftAddonCls}>{leftAddon}</span>
          </div>
        )}

        {/** content / input */}
        {renderInput &&
          renderInput(
            inputCls,
            Boolean(leftAddonDisabled),
            inputRef,
            setLeftAddonBorderSelected
          )}

        {/** clear input */}
        {handleClearInput && !!value && (
          <span
            onClick={handleClearInput}
            className="cursor-pointer right-3 top-2.5 absolute text-gray-400 hover:text-black"
          >
            {clearInput}
          </span>
        )}

        {/** right addon */}
        {rightAddon && (
          <span
            className={cx(
              'cursor-pointer absolute top-4 right-4',
              rightAddonCls
            )}
          >
            {rightAddon}
          </span>
        )}
      </div>
    </div>
  );
};

export const TextInput: FC<TextInputProps & InputProps> = ({
  errored = false,
  clsWrapper,
  rightAddon,
  leftAddon,
  rightAddonCls,
  clearInput,
  handleClearInput,
  leftAddonDisabled,
  leftAddonCls,
  leftAddonBorder,
  ...rest
}) => (
  <InputWrapper
    errored={errored}
    value={rest.value}
    clsWrapper={clsWrapper}
    rightAddon={rightAddon}
    leftAddon={leftAddon}
    rightAddonCls={rightAddonCls}
    clearInput={clearInput}
    handleClearInput={handleClearInput}
    leftAddonCls={leftAddonCls}
    leftAddonBorder={leftAddonBorder}
    displayType={rest.displayType || 'input'}
    leftAddonDisabled={leftAddonDisabled || Boolean(rest.disabled)}
  >
    {(inputCls, disabled, node, setLeftAddonBorderSelected) => (
      <input
        {...(rest as TextInputProps)}
        id={rest.name}
        className={inputCls}
        data-testid={rest.name}
        type={rest.type || 'text'}
        disabled={rest?.disabled || disabled}
        ref={node as MutableRefObject<HTMLInputElement>}
        onFocus={e => {
          if (rest.onFocus) {
            rest.onFocus(e);
          }
          if (!setLeftAddonBorderSelected) {
            return;
          }
          setLeftAddonBorderSelected(true);
        }}
        onBlur={e => {
          if (rest.onBlur) {
            rest.onBlur(e);
          }
          if (!setLeftAddonBorderSelected) return;
          setLeftAddonBorderSelected(false);
        }}
      />
    )}
  </InputWrapper>
);
