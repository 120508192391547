import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { TransferFilterFormikField, TransferFilterFormikProps } from 'common';
import { ArrowSmLeftIcon, XIcon } from '@heroicons/react/outline';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import cx from 'classnames';
import { FormikProps } from 'formik';
import CheckBox from 'rc-checkbox';
import { API } from 'api';
import { TransactionFilterSteps } from './steps';
import { useFilterTransactions } from './use-filter-transactions';

export const FilterStatus: FC<
  Partial<StepWizardChildProps & FormikProps<TransferFilterFormikProps>>
> = ({ goToNamedStep }) => {
  /**
   * Hooks
   */
  const { filterStatus, handleFilterValuesUpdate, handleFilterSelection } =
    useFilterTransactions();

  if (!goToNamedStep) {
    return null;
  }

  /**
   * DOM
   */
  const onCancelPreview = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(TransactionFilterSteps.FilterMain);
  };

  return (
    <div className="flex flex-col">
      {/* header  */}
      <DashboardHeader asStickyHeader headerPadding="pt-14">
        <div className="px-10 mt-9 flex flex-row gap-x-2">
          <button onClick={onCancelPreview} type="button" aria-label="go back">
            <ArrowSmLeftIcon className="w-8 h-8 mr-3 absolute left-8 top-9 z-30" />
          </button>
          <h3 className="font-semibold typo-20 md:typo-24 ml-8 absolute top-9 z-30 pr-20">
            Choose status
          </h3>
          <button onClick={onCancelPreview} type="button" aria-label="go back">
            <XIcon className="w-6 h-6 absolute right-8 top-10 z-30" />
          </button>
        </div>
      </DashboardHeader>
      <div className="rounded max-h-485 overflow-y-scroll">
        {Object.values(API.TransactionStatusFilter)?.map(activity => (
          <div
            key={activity}
            onClick={() =>
              handleFilterValuesUpdate(
                activity,
                TransferFilterFormikField?.filterStatus
              )
            }
            className={cx(
              'flex flex-row justify-between px-4 py-2.5 border-b items-center cursor-pointer hover-bg-grey-brighter',
              { 'bg-gray-100': filterStatus?.includes(activity) }
            )}
          >
            <span className="flex flex-row gap-x-2.5 items-center">
              {activity}
            </span>
            <CheckBox
              className="app-input-checkbox-wrap"
              checked={filterStatus?.includes(activity)}
              onChange={() =>
                handleFilterValuesUpdate(
                  activity,
                  TransferFilterFormikField?.filterStatus
                )
              }
            />
          </div>
        ))}
      </div>
      <div className="flex flex-col sticky bottom-0 sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
        {/* cancel button  */}
        <button
          type="button"
          disabled={!filterStatus?.length}
          className="app-button-outline button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
          onClick={() =>
            handleFilterValuesUpdate(
              [],
              TransferFilterFormikField?.filterStatus
            )
          }
        >
          Clear selection
        </button>
        {/* preview  */}
        <button
          role="button"
          type="button"
          className="app-button-primary button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
          onClick={() => {
            handleFilterSelection(TransferFilterFormikField?.filterStatus);
            goToNamedStep(TransactionFilterSteps.FilterMain);
          }}
        >
          Submit selection
        </button>
      </div>
    </div>
  );
};
