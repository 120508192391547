import { Link } from 'react-router-dom';
import {
  AppContainer,
  EnrichedAccountDetailAsset,
  sortFunds,
  SORT_ASSETS_BY,
  SORT_DIR,
  EnrichedAccountDetail,
  HoldingsTableColumnId,
} from 'common';
import cx from 'classnames';
import { useCallback } from 'react';
import {
  useAppTableSort,
  AppTableRows,
  AppTableHeadings,
  SortDirection,
} from '../../app-table';
import { SortHoldingsProps, SortInfo } from '~/store/types';
import {
  getHoldingsColumns,
  HoldingsTableOnAction,
} from '~/utils/get-holdings-columns';
import { APP_ROUTES } from '~/routes';
import { handleNavigation } from '~/components/shared/url-params/url-params';
import { API } from 'api';

export const sortAndFilterHoldingsData = (
  holdings: EnrichedAccountDetailAsset[],
  sortInfo: SortInfo<HoldingsTableColumnId> | null
) => {
  const result = holdings
    .filter(holding => holding?.currency?.code)
    .filter(holding => holding?.currency);

  if (!sortInfo) {
    return result;
  }

  const sortDir =
    sortInfo?.direction === SortDirection.ASC ? SORT_DIR.ASC : SORT_DIR.DESC;

  if (sortInfo?.column === HoldingsTableColumnId.Quantity) {
    return sortFunds(result, SORT_ASSETS_BY.BALANCE, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.Price) {
    return sortFunds(result, SORT_ASSETS_BY.PRICE, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.Value) {
    return sortFunds(result, SORT_ASSETS_BY.BALANCE_USD, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.APY) {
    return sortFunds(result, SORT_ASSETS_BY.APY, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.Change24h) {
    return sortFunds(result, SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_DAY, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.TotalInterestEarned) {
    return sortFunds(result, SORT_ASSETS_BY.TOTAL_INTEREST_EARNED, sortDir);
  }

  if (sortInfo?.column === HoldingsTableColumnId.AccruedInterestThisWeek) {
    return sortFunds(result, SORT_ASSETS_BY.ACCRUED_INTEREST_WEEK, sortDir);
  }

  // Default: ORDER BY name ASC
  return sortFunds(result, SORT_ASSETS_BY.CODE, sortDir);
};

interface Props {
  accountDetail: EnrichedAccountDetail | null;
  onAction?: HoldingsTableOnAction;
  sortData?: SortHoldingsProps | undefined;
  onSortData?: (arg: SortInfo<HoldingsTableColumnId> | null) => void;
}

enum SORT_PARAMS {
  ACCOUNT_DETAIL_HOLDINGS_COLUMN = 'accountDetailHoldingsTableColumnId',
  ACCOUNT_DETAIL_HOLDINGS_DIRECTION = 'accountDetailHoldingsDirection',
}

export const PortfolioAccountHoldings = ({
  accountDetail,
  onAction,
}: Props) => {
  /**
   * Hooks
   */
  const {
    applyParams,
    queryParams: {
      accountDetailHoldingsTableColumnId,
      accountDetailHoldingsDirection,
    },
  } = handleNavigation<SORT_PARAMS>();
  const { columnProps, sortInfo } = useAppTableSort<HoldingsTableColumnId>({
    column:
      HoldingsTableColumnId[
        accountDetailHoldingsTableColumnId as HoldingsTableColumnId
      ] || HoldingsTableColumnId.Value,
    direction:
      SortDirection[accountDetailHoldingsDirection as SortDirection] ||
      SortDirection.DESC,
  });

  const handleSortInfoChange = useCallback(arg => {
    applyParams({
      [SORT_PARAMS.ACCOUNT_DETAIL_HOLDINGS_COLUMN]: arg?.column,
      [SORT_PARAMS.ACCOUNT_DETAIL_HOLDINGS_DIRECTION]: arg?.direction,
    });
  }, []);

  /**
   * DOM
   */
  const columns = getHoldingsColumns(
    accountDetail?.holdingsTable.columns,
    columnProps,
    onAction
  );
  const rows = sortAndFilterHoldingsData(
    accountDetail?.assetsWithBalanceOrEarnedInterest || [],
    sortInfo
  );

  return (
    <AppContainer fullHeight cls="text-primary">
      <main className="border-grey-bright bg-white border-1 border-b-0 rounded-t-4 flex items-center justify-between px-4 md:px-8 py-4">
        <div>
          <div className="text-primary text-base font-bold">
            {accountDetail?.labels.holdingsTableTitle}
          </div>
          <div className="text-sm font-normal text-grey-darker">
            {accountDetail?.labels.holdingsTableSubtitle}
          </div>
        </div>
        {/* TODO: https://stablehouse.atlassian.net/browse/SH-10087 */}
        {/* <button type="button" className="app-button-outline flex gap-x-1.5">
          <span className="font-bold md:text-sm text-sm hidden md:block">
            Export CSV
          </span>
        </button> */}
      </main>
      <div className="snap-x snap-mandatory min-w-7xl max-w-7xl overflow-x-auto">
        <div className="min-w-780">
          <AppTableHeadings<HoldingsTableColumnId, EnrichedAccountDetailAsset>
            columns={columns}
            borderCls={cx('border-grey-bright bg-white border-1 border-b-0', {
              'border-b-0': rows?.length,
              hidden: !rows?.length,
            })}
            handleSort={handleSortInfoChange}
          />
          <AppTableRows<HoldingsTableColumnId, EnrichedAccountDetailAsset>
            as={Link}
            to={row => `${APP_ROUTES.AUTH_ASSET}/${row.currency.code}`}
            columns={columns}
            cellRowCls="gap-x-2"
            rowPadding="px-4 md:px-8 py-3"
            borderBottomColor="grey-bright"
            rows={rows as EnrichedAccountDetailAsset[]}
            cellItemCls="text-sm md:text-base truncate"
          />
        </div>
      </div>
    </AppContainer>
  );
};
