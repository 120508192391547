import { FC, memo, useState } from 'react';
import cx from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { BaseForm } from '../definitions/base-form';
import { APP_ROUTES } from '../routes';
import { DataStore } from '../store';
import { handleApiException } from '../utils/handle-errors';
import { nameof } from '../utils/typescript-helpers';
import { IconContent } from '../components/icon-content';
import { Error } from '../components/common/error';
import { ExternalSplashLayout } from '../components/layouts/external-splash';
import { AlreadyHaveAccount } from '../components/common/already-have-account';
import { TextField } from '../components/forms/text-field';
import { DebounceField } from '../components/forms/debounce-field';
import memoize from 'memoizee';
import {
  TIMERS,
  useComputedConfig,
  useRecaptcha,
  useSystemConfig,
  ERROR_MESSAGES,
} from 'common';
import {
  usePasswordLabel,
  useTogglePassword,
} from '../hooks/use-toggle-password';

enum FormField {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

interface FormProps extends BaseForm {
  [FormField.password]: string;
  [FormField.confirmPassword]: string;
}

interface Props {
  title?: string;
  showWelcomeMessage?: boolean;
}

export const SetPassword: FC<Props> = ({
  title = `Welcome`,
  showWelcomeMessage = true,
}) => {
  /**
   * State
   */
  const [message, setMessage] = useState('');
  const [showPassword, toggleShowPassword, passwordType] = useTogglePassword();

  /**
   * Store
   */
  const resetPasswordWithToken = DataStore.useStoreActions(
    a => a.user.resetPasswordWithToken
  );
  const getPasswordEntropy = DataStore.useStoreActions(
    a => a.user.getPasswordEntropy
  );

  /**
   * Hooks
   */
  const { token } = useParams();
  const { tenant } = useComputedConfig();
  const { recaptchaWebappPublicKey } = useSystemConfig();
  const { getRecaptchaToken } = useRecaptcha({
    key: recaptchaWebappPublicKey,
  });

  /**
   * Methods
   */
  const onFormSubmit = async (
    values: FormProps,
    helper: FormikHelpers<FormProps>
  ) => {
    try {
      setMessage('');
      helper.setFieldValue(nameof<BaseForm>('errorMessage'), '');
      const error = await resetPasswordWithToken({
        token: token as string,
        password: values[FormField.password],
        recaptchaToken: await getRecaptchaToken(),
      });
      if (!error) {
        setMessage('Your password has been reset successfully.');
      } else {
        helper.setFieldValue(nameof<BaseForm>('errorMessage'), error);
      }
    } catch (exception) {
      handleApiException(exception, helper);
    }
  };

  const validatePassword = async (password: string) => {
    return await getPasswordEntropy({
      password,
    });
  };

  const memoizedValidatePassword = memoize(validatePassword);

  const validate = async ({ password, confirmPassword }: FormProps) => {
    const errors: Record<string, unknown> = {};

    if (!password) {
      errors[FormField.password] = ERROR_MESSAGES.REQUIRED_VALUE;
    }

    // pwd check
    const response = await memoizedValidatePassword(password);
    if (response && !response.isValid) {
      errors[FormField.password] = ERROR_MESSAGES.INVALID_VALUE;
    }

    // repeat pwd check
    if (password && password !== confirmPassword) {
      errors[FormField.confirmPassword] = ERROR_MESSAGES.PASSWORD_MUST_MATCH;
    }

    return errors;
  };

  const onFormValidate = async (values: FormProps) => {
    return validate(values).then((errors = {}) => {
      return errors;
    });
  };

  /**
   * DOM
   */
  const initialValues = {
    [FormField.password]: '',
    [FormField.confirmPassword]: '',
  };
  return (
    <ExternalSplashLayout title={title}>
      {showWelcomeMessage && (
        <div className="sm:max-w-md">
          <p className="text-base opacity-70 mb-8">
            Before you start exploring your account, please set a secure
            password below. Once you’ve set your password, you’ll be asked to
            log in using the email address provided during onboarding and the
            new password you’re about to create.
          </p>
        </div>
      )}

      {/* form  */}
      <div className="flex flex-1 flex-col">
        {message ? (
          <div className="my-10 flex flex-col sm:block">
            {/* message  */}
            <IconContent content={<p>{message}</p>} />
            {/* sep  */}
            <hr className="mt-10 mb-8 " />
            <Link
              role="button"
              className="app-button-accent text-center"
              to={APP_ROUTES.NON_AUTH_HOME}
            >
              Sign in
            </Link>
          </div>
        ) : (
          <Formik<FormProps>
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={onFormValidate}
            validateOnChange
          >
            {({ values, errors, isSubmitting }) => (
              <Form className="mt-4">
                {/* api error  */}
                {values.errorMessage && (
                  <Error
                    testid="authentication-failed"
                    message={values.errorMessage}
                  />
                )}

                <div className="sm:max-w-md">
                  {/* password  */}
                  <div className="flex flex-col">
                    <DebounceField
                      name={FormField.password}
                      label={usePasswordLabel(
                        'New Password',
                        showPassword,
                        toggleShowPassword
                      )}
                      type={passwordType}
                      validator="password"
                      debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                    />
                  </div>

                  {/* new password  */}
                  <div className="flex flex-col">
                    <TextField
                      type={passwordType}
                      name={FormField.confirmPassword}
                      label="Confirm password"
                    />
                  </div>
                </div>
                {/* sep  */}
                <hr className="my-8" />

                {/* actions  */}
                <div className="flex flex-col sm:items-start">
                  {/* TODO: formik isSubmitting should be a wired up to busy state  */}
                  <button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    className={cx('app-button-accent relative')}
                  >
                    Submit
                  </button>

                  {/* already know password  */}
                  <AlreadyHaveAccount
                    tag="Already know your password?"
                    cls="mt-3"
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </ExternalSplashLayout>
  );
};

export const ResetPassword = memo(() => {
  return <SetPassword title={`Reset Password`} showWelcomeMessage={false} />;
});
